@tailwind base;
@tailwind components;
@tailwind utilities;

/* Change the scrollbar track */

.timeline-container {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.timeline-container,.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}


::-webkit-scrollbar-track {
    background: white; 
  }
  
  /* Change the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background: #396EFF; 
    border-radius: 10px;
  }
  
  /* Change the scrollbar thumb when hovered */
  ::-webkit-scrollbar-thumb:hover {
    background: #8BAAFE; 
  }
  
  /* Change the scrollbar width and height */
  ::-webkit-scrollbar {
    width: 10px; /* For vertical scrollbars */
    height: 12px; /* For horizontal scrollbars */
  }
  
